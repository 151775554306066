import React from 'react';
import Layout from '../components/LayoutComponent';
import {
    Box,
    Container,
    Card,
    CardContent,
    Typography,
    Link,
    Accordion,
    AccordionSummary,
    AccordionDetails,
    useMediaQuery,
} from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import Seo from '../components/SeoComponent';

import { styled, useTheme } from '@mui/material/styles';

const StyledAccordion = styled(Accordion)({
    '&': {},

    '&.Mui-expanded': {
        backgroundColor: '#F0F0F5',
        color: '#8223D2',
    },
    '&:nth-of-type(1n)': {
        border: '1px solid #D5D6DA',
        borderRight: 0,
        borderLeft: 0,
    },
});

const StyledAccordionDetails = styled(AccordionDetails)({
    backgroundColor: '#F0F0F5',
    color: 'black',
});

function policy() {
    const theme = useTheme();
    const isSmall = useMediaQuery(theme.breakpoints.down('lg'));

    return (
        <>
            <Seo
                title={`Policy Documents | Asurion Mobile+`}
                description={`Asurion Mobile+ policy documents for new policies, such as Product Disclosure Statement (PDS) and updates, Policy Schedule, and Target Market Determination.`}
            />

            <Layout
                subcomponent={
                    <Container maxWidth="md" sx={{ height: '100%', padding: '0 !important' }}>
                        <Card sx={{ my: 5, boxShadow: '0 0 8px 5px rgb(16 0 0 / 15%)' }}>
                            <CardContent sx={{ my: 5 }}>
                                <Box component="div">
                                    <Typography
                                        variant="h1"
                                        fontWeight="bold"
                                        textAlign="center"
                                        sx={{ fontSize: '2.25rem', mb: 3 }}
                                        gutterBottom
                                    >
                                        Asurion Mobile+ Policy Documents
                                    </Typography>
                                </Box>
                                <Box component="div" sx={{ mx: isSmall ? 0 : 25 }}>
                                    <Typography
                                        fontWeight="600"
                                        fontSize="1rem"
                                        lineHeight="1.5rem"
                                        color="text.secondary"
                                    >
                                        Current documents
                                    </Typography>
                                    <Typography fontSize="1rem" lineHeight="1.5rem">
                                        Asurion Mobile+ policy documents for new policies quoted for and purchased on or
                                        after 20 April 2022.
                                    </Typography>
                                    <hr />
                                    <Typography fontWeight="600" fontSize="1rem" lineHeight="1.5rem">
                                        Lite Policy
                                    </Typography>
                                    <Link
                                        href="/resources/docs/asurionmobileplus-fsg-pds-lite.pdf"
                                        sx={{ color: '#0000FF' }}
                                        id="btn-home-lite-pds"
                                        target="_blank"
                                    >
                                        <Typography
                                            component="li"
                                            fontSize="1rem"
                                            lineHeight="1.5rem"
                                            sx={{ listStyleType: 'disc' }}
                                        >
                                            Asurion Mobile+ (Lite) Combined Financial Services Guide and Product
                                            Disclosure Statement
                                        </Typography>
                                    </Link>
                                    <hr />
                                    <Typography fontWeight="600" fontSize="1rem" lineHeight="1.5rem">
                                        Classic Policy
                                    </Typography>
                                    <Link
                                        href={encodeURI(`/resources/docs/asurionmobileplus-fsg-pds-classic.pdf`)}
                                        sx={{ color: '#0000FF' }}
                                        id="btn-home-classic-pds"
                                        target="_blank"
                                    >
                                        <Typography
                                            component="li"
                                            fontSize="1rem"
                                            lineHeight="1.5rem"
                                            sx={{ listStyleType: 'disc' }}
                                        >
                                            Asurion Mobile+ (Classic) Combined Financial Services Guide and Product
                                            Disclosure Statement
                                        </Typography>
                                    </Link>
                                    <hr />
                                    <Typography fontWeight="600" fontSize="1rem" lineHeight="1.5rem">
                                        Prestige Policy
                                    </Typography>
                                    <Link
                                        href={encodeURI(`/resources/docs/asurionmobileplus-fsg-pds-prestige.pdf`)}
                                        sx={{ color: '#0000FF' }}
                                        id="btn-home-prestige-pds"
                                        target="_blank"
                                    >
                                        <Typography
                                            component="li"
                                            fontSize="1rem"
                                            lineHeight="1.5rem"
                                            sx={{ listStyleType: 'disc' }}
                                        >
                                            Asurion Mobile+ (Prestige) Combined Financial Services Guide and Product
                                            Disclosure Statement
                                        </Typography>
                                    </Link>
                                    <hr />
                                    <Typography fontWeight="600" fontSize="1rem" lineHeight="1.5rem">
                                        Target Market Determination
                                    </Typography>
                                    <Link
                                        href={encodeURI(`/resources/docs/asurionmobileplus-TMD-FINAL.pdf`)}
                                        sx={{ color: '#0000FF' }}
                                        target="_blank"
                                        id="btn-home-target-market-pds"
                                    >
                                        <Typography
                                            component="li"
                                            fontSize="1rem"
                                            lineHeight="1.5rem"
                                            sx={{ listStyleType: 'disc' }}
                                        >
                                            Target Market Determination
                                        </Typography>
                                    </Link>
                                    <br />

                                    <StyledAccordion disableGutters elevation={0} square>
                                        <AccordionSummary
                                            aria-controls="panel1d-content"
                                            expandIcon={<ExpandMoreIcon />}
                                            sx={{ flexDirection: 'row-reverse' }}
                                        >
                                            <Typography fontWeight="600" fontSize="1.25rem" lineHeight="1.75rem">
                                                Past documents
                                            </Typography>
                                        </AccordionSummary>
                                        <StyledAccordionDetails>
                                            <Typography fontSize="1rem" lineHeight="1.5rem">
                                                Asurion Mobile+ policy documents for policies quoted for and purchased
                                                between 11 February 2022 to 19 April 2022.
                                            </Typography>
                                            <hr />
                                            <Typography fontWeight="600" fontSize="1rem" lineHeight="1.5rem">
                                                Lite Policy
                                            </Typography>
                                            <Link
                                                href="/resources/docs/asurionmobileplus-fsg-pds-lite-feb-11-22.pdf"
                                                sx={{ color: '#0000FF' }}
                                                id="btn-home-lite-pds-feb-11-22"
                                                target="_blank"
                                            >
                                                <Typography
                                                    component="li"
                                                    fontSize="1rem"
                                                    lineHeight="1.5rem"
                                                    sx={{ listStyleType: 'disc' }}
                                                >
                                                    Asurion Mobile+ (Lite) Combined Financial Services Guide and Product
                                                    Disclosure Statement
                                                </Typography>
                                            </Link>
                                            <hr />
                                            <Typography fontWeight="600" fontSize="1rem" lineHeight="1.5rem">
                                                Classic Policy
                                            </Typography>
                                            <Link
                                                href={encodeURI(
                                                    `/resources/docs/asurionmobileplus-fsg-pds-classic-11-feb-22.pdf`
                                                )}
                                                sx={{ color: '#0000FF' }}
                                                id="btn-home-classic-pds-feb-11-22"
                                                target="_blank"
                                            >
                                                <Typography
                                                    component="li"
                                                    fontSize="1rem"
                                                    lineHeight="1.5rem"
                                                    sx={{ listStyleType: 'disc' }}
                                                >
                                                    Asurion Mobile+ (Classic) Combined Financial Services Guide and
                                                    Product Disclosure Statement
                                                </Typography>
                                            </Link>
                                            <hr />
                                            <Typography fontWeight="600" fontSize="1rem" lineHeight="1.5rem">
                                                Prestige Policy
                                            </Typography>
                                            <Link
                                                href={encodeURI(
                                                    `/resources/docs/asurionmobileplus-fsg-pds-prestige-11-feb-22.pdf`
                                                )}
                                                sx={{ color: '#0000FF' }}
                                                id="btn-home-prestige-pds-feb-11-22"
                                                target="_blank"
                                            >
                                                <Typography
                                                    component="li"
                                                    fontSize="1rem"
                                                    lineHeight="1.5rem"
                                                    sx={{ listStyleType: 'disc' }}
                                                >
                                                    Asurion Mobile+ (Prestige) Combined Financial Services Guide and
                                                    Product Disclosure Statement
                                                </Typography>
                                            </Link>
                                            <hr />
                                            <Typography fontSize="1rem" lineHeight="1.5rem">
                                                Asurion Mobile+ policy documents for policies quoted for and purchased
                                                between 27 October 2021 to 10 February 2022.
                                            </Typography>
                                            <hr />
                                            <Typography fontWeight="600" fontSize="1rem" lineHeight="1.5rem">
                                                Lite Policy
                                            </Typography>
                                            <Link
                                                href="/resources/docs/asurionmobileplus-fsg-pds-lite-27-Oct-2021.pdf"
                                                sx={{ color: '#0000FF' }}
                                                id="btn-home-lite-pds"
                                                target="_blank"
                                            >
                                                <Typography
                                                    component="li"
                                                    fontSize="1rem"
                                                    lineHeight="1.5rem"
                                                    sx={{ listStyleType: 'disc' }}
                                                >
                                                    Asurion Mobile+ (Lite) Combined Financial Services Guide and Product
                                                    Disclosure Statement
                                                </Typography>
                                            </Link>
                                            <hr />
                                            <Typography fontWeight="600" fontSize="1rem" lineHeight="1.5rem">
                                                Classic Policy
                                            </Typography>
                                            <Link
                                                href={encodeURI(
                                                    `/resources/docs/asurionmobileplus-fsg-pds-classic-27-Oct-2021.pdf`
                                                )}
                                                sx={{ color: '#0000FF' }}
                                                id="btn-home-classic-pds"
                                                target="_blank"
                                            >
                                                <Typography
                                                    component="li"
                                                    fontSize="1rem"
                                                    lineHeight="1.5rem"
                                                    sx={{ listStyleType: 'disc' }}
                                                >
                                                    Asurion Mobile+ (Classic) Combined Financial Services Guide and
                                                    Product Disclosure Statement
                                                </Typography>
                                            </Link>
                                            <hr />
                                            <Typography fontWeight="600" fontSize="1rem" lineHeight="1.5rem">
                                                Prestige Policy
                                            </Typography>
                                            <Link
                                                href={encodeURI(
                                                    `/resources/docs/asurionmobileplus-fsg-pds-prestige-27-Oct-2021.pdf`
                                                )}
                                                sx={{ color: '#0000FF' }}
                                                id="btn-home-prestige-pds"
                                                target="_blank"
                                            >
                                                <Typography
                                                    component="li"
                                                    fontSize="1rem"
                                                    lineHeight="1.5rem"
                                                    sx={{ listStyleType: 'disc' }}
                                                >
                                                    Asurion Mobile+ (Prestige) Combined Financial Services Guide and
                                                    Product Disclosure Statement
                                                </Typography>
                                            </Link>
                                            <hr />
                                        </StyledAccordionDetails>
                                    </StyledAccordion>
                                    <StyledAccordion disableGutters elevation={0} square>
                                        <AccordionSummary
                                            aria-controls="panel1d-content"
                                            expandIcon={<ExpandMoreIcon />}
                                            sx={{ flexDirection: 'row-reverse' }}
                                        >
                                            <Typography fontWeight="600" fontSize="1.25rem" lineHeight="1.75rem">
                                                Glossary of terms
                                            </Typography>
                                        </AccordionSummary>
                                        <StyledAccordionDetails>
                                            <Typography fontWeight="600" fontSize="1rem" lineHeight="1.5rem">
                                                Product Disclosure Statement (PDS)
                                            </Typography>
                                            <Typography fontSize="1rem" lineHeight="1.5rem">
                                                A Product Disclosure Document (PDS) is an important legal document that
                                                contains the terms and conditions of your cover, including what is and
                                                isn’t covered. You should read this together with your Policy Schedule.
                                            </Typography>
                                            <br />
                                            <Typography fontWeight="600" fontSize="1rem" lineHeight="1.5rem">
                                                Supplementary Product Disclosure Statement (SPDS)
                                            </Typography>
                                            <Typography fontSize="1rem" lineHeight="1.5rem">
                                                Contains updates to the PDS.
                                            </Typography>
                                            <br />
                                            <Typography fontWeight="600" fontSize="1rem" lineHeight="1.5rem">
                                                Policy Schedule
                                            </Typography>
                                            <Typography fontSize="1rem" lineHeight="1.5rem">
                                                A policy schedule provides you with confirmation of the insurance cover
                                                you purchased, including when it starts and ends, the type of insurance
                                                it is, how much you paid and the excesses that are applicable.
                                            </Typography>
                                        </StyledAccordionDetails>
                                    </StyledAccordion>
                                </Box>
                            </CardContent>
                        </Card>
                    </Container>
                }
            />
        </>
    );
}

export default policy;
